import * as React from "react"
import logo from '../images/logo.png'
import '../styles.scss'

const IndexPage = () => (
  <img
  src={logo}
  alt='Montana Concept'
  style={{position:'fixed', left:'50%', top:'50%', margin:'auto', transform:'translateX(-50%) translateY(-50%)'}} />
)

export default IndexPage